import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Layout from '../../components/layout'
import { Grid, Link, Typography } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import PaginationItem from '@material-ui/lab/PaginationItem'
import styles from './index.module.css'
import News from '../../components/news'
import Img from 'gatsby-image'

class ListaArticoliTemplate extends React.Component {
  render() {
    const articoliList = get(
      this.props,
      'data.allContentfulArticolo.nodes'
    ).map((articolo) => articolo)
    const categoria = get(this.props, 'pageContext.categoria')
    const pageTitle = `Movimento Rivoluzione Fiscale - Lista articoli${
      categoria ? ` - ${categoria}` : ''
    }`

    const slug = get(this.props, 'pageContext.slug')
    const total = get(this.props, 'pageContext.total')
    const maxPages = (total - 4 > 0 ? (total - 4) % 5 : 0) + 1
    const page = get(this.props, 'pageContext.page')
    const handleChange = (event, value) => {
      window.location.href = `/${slug}/${value}/`
    }

    return (
      <Layout location={this.props.location}>
        <Helmet title={pageTitle}>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
        <div className={styles.container}>
          <div className={styles.gridContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={styles.titleContainer}>
                <Typography variant={'h3'}>{pageTitle}</Typography>
              </Grid>
              {articoliList.filter(a => a.titolo !== "NON ELIMINARE").map((articolo) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  spacing={2}
                  key={`article-list-${articolo.id}`}
                >
                  <Link href={`/articoli/${articolo.slug}/`}>
                    <Img
                      className={styles.img}
                      alt=""
                      fluid={articolo.heroImage.fluid}
                    />
                  </Link>
                  <Link href={`/articoli/${articolo.slug}/`}>
                    <Typography variant={'h3'} className={styles.titleArticle}>
                      {articolo.titolo}
                    </Typography>
                  </Link>
                  {articolo.autore && (
                    <Typography variant={'subtitle1'} className={styles.author}>
                      {`${articolo.autore.nome} ${articolo.autore.cognome}, ${articolo.dataPubblicazione}`}
                    </Typography>
                  )}
                </Grid>
              ))}
            </Grid>
            <Pagination
              classes={{
                root: styles.paginationRoot,
              }}
              renderItem={(item) => (
                <PaginationItem
                  classes={{
                    selected: styles.paginationItemSelected,
                  }}
                  {...item}
                />
              )}
              count={maxPages}
              page={page}
              onChange={handleChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export default ListaArticoliTemplate

export const pageQuery = graphql`
  query ArticoloList($categoriaRegex: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulArticolo(
      limit: 12
      filter: { categorie: { elemMatch: { slug: { regex: $categoriaRegex } } } }
      sort: { fields: [dataPubblicazione], order: DESC }
    ) {
      nodes {
        id
        slug
        titolo
        contenuto {
          childMarkdownRemark {
            html
          }
        }
        autore {
          cognome
          nome
          descrizione
        }
        dataPubblicazione(formatString: "MMMM Do, YYYY")
        heroImage {
          fluid(maxWidth: 1180, background: "rgb:000000") {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
